import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../layouts'
import SEO from '../components/SEO'
import Product from '../components/Product'
import Container from '../components/Container'
import PostListHorizontal from '../components/PostListHorizontal'
import PostCard from '../components/PostCard'
import Adsense from '../components/Adsense'
import Disqus from '../components/Disqus'
import Share from '../components/Share'

import './index.scss'

class Index extends React.Component {
  render () {
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO article={false} />
        <Product siteMeta={this.props.data.site.siteMetadata} />
        <div className="home-ad">
          <Adsense type="article-top-responsive" />
        </div>
        <div className="home-blog-section">
          <Container>
            <h2 className="blog-latest-title">Latest From The Blog</h2>
            <div className="clearfix">
              <div className="blog-featured">
                <PostCard
                  post={posts[0].node}
                  type="featured"
                  siteMeta={this.props.data.site.siteMetadata}
                />
              </div>
              <div className="blog-latest">
                <PostListHorizontal
                  posts={posts.slice(1, 5)}
                  siteMeta={this.props.data.site.siteMetadata}
                />
              </div>
            </div>
            <div className="home-ad">
              <Adsense type="article-bottom-responsive" />
            </div>
            <div className="blog-about">
              <h2 className="blog-about-title">
                About {this.props.data.site.siteMetadata.name}
              </h2>
              <div className="info-group">
                <div className="one-half">
                  <p>
                    URL Encoder is a simple and easy to use online tool for
                    encoding URLs. You just need to type or paste a string in
                    the input text area, the tool will automatically convert
                    your string to URL encoded format in real time. Once the URL
                    is encoded, you can click in the output text area to copy
                    the encoded URL. Note that, our tool uses <code>UTF-8</code>{' '}
                    encoding scheme for encoding URLs. The{' '}
                    <a href="https://www.w3.org/TR/html40/appendix/notes.html#non-ascii-chars">
                      world wide web consortium recommends
                    </a>{' '}
                    that <code>UTF-8</code> should be used for encoding.
                  </p>
                  <p>
                    Apart from the tool, our website also contains various
                    articles about how to encode URLs in different programming
                    languages.
                  </p>

                  <h3>What is URL encoding or Percent Encoding?</h3>
                  <p>
                    URLs in the world wide web can only contain ASCII
                    alphanumeric characters and some other safe characters like
                    hyphen (<code>-</code>), underscore (<code>_</code>), tilde
                    (<code>~</code>), and dot (<code>.</code>).
                  </p>
                  <blockquote>
                    Alphabets &nbsp;&nbsp;/&nbsp;&nbsp; Digits
                    &nbsp;&nbsp;/&nbsp;&nbsp; "-" &nbsp;&nbsp;/&nbsp;&nbsp; "_"
                    &nbsp;&nbsp;/&nbsp;&nbsp; "~" &nbsp;&nbsp;/&nbsp;&nbsp; "."
                  </blockquote>
                  <p>
                    Any other character apart from the above list must be
                    encoded.
                  </p>
                  <p>
                    URL encoding, also known as percent encoding, is a way to
                    encode or escape reserved, unprintable, or non-ASCII
                    characters in URLs to a safe and secure format that can be
                    transmitted over the internet. It is also used in preparing
                    data for submitting HTML forms with content-type{' '}
                    <code>application/x-www-form-urlencoded</code>.{' '}
                  </p>
                  <h3>How does URL encoding work?</h3>
                  <p>
                    URL Encoding works like this - It first converts the
                    character to one or more bytes. Then each byte is
                    represented by two hexadecimal digits preceded by a percent
                    sign (%) - (e.g. %xy). That gives us the URL encoded value.
                  </p>
                  <p>
                    The percent sign is used as an escape character that's why
                    we also refer to URL encoding as Percent encoding.
                  </p>
                  <blockquote>Percent Encoded = "%" HEXDIG HEXDIG</blockquote>
                  <p>
                    For instance, Let's understand how to URL encode the
                    character <code>@</code>. To encode <code>@</code>, we first
                    convert it into a sequence of bytes using its ASCII value.
                    The ASCII value of <code>@</code> in decimal is{' '}
                    <code>64</code> which when converted to hexadecimal comes
                    out to be <code>40</code>. We then precede the hex value
                    with percent sign, which gives us the final URL encoded
                    value <code>%40</code>.
                  </p>
                  <h3>URL Encoding character classification</h3>
                  <p>
                    Following is the classification of different types of
                    characters that cannot be placed directly inside URLs -
                  </p>
                  <p>
                    <strong>ASCII control characters:</strong> Characters in the
                    range <code>0-31</code> and <code>127</code> in the ASCII
                    character set are control characters. These characters are
                    unprintable and cannot be placed directly inside any URL
                    without encoding. Some examples of control characters
                    include backspace, carriage return, line feed, vertical tab,
                    horizontal tab etc.
                  </p>
                </div>
                <div className="one-half">
                  <p>
                    <strong>Reserved characters:</strong> Characters like{' '}
                    <code>?</code>, <code>:</code>, <code>/</code>,{' '}
                    <code>#</code>, <code>&amp;</code> have special meaning
                    within URLs. Therefore you can't place them directly inside
                    URLs without encoding or escaping.
                  </p>
                  <p>
                    <strong>Unsafe characters:</strong> Many characters like{' '}
                    <code>space</code>, <code>&lt;</code>, <code>&gt;</code>,{' '}
                    <code>{'{'}</code>, <code>{'}'}</code> are unsafe and must
                    be encoded before placing them inside URLs.
                  </p>
                  <p>
                    <strong>Non ASCII characters:</strong> Finally, you cannot
                    securely transmit any character outside the ASCII character
                    set inside URLs. You must encode them.
                  </p>
                  <div className="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th>Classification</th>
                          <th>Characters</th>
                          <th>Encoding required?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Safe characters</td>
                          <td>
                            Alphabets (<code>A-Z a-z</code>), Digits (
                            <code>0-9</code>), hyphen (<code>-</code>), underscore
                            (<code>_</code>) tilde (<code>~</code>), and dot (
                            <code>.</code>)
                          </td>
                          <td>No</td>
                        </tr>
                        <tr>
                          <td>ASCII control characters</td>
                          <td>
                            Characters in the range <code>0-31</code> and{' '}
                            <code>127</code> in the ASCII character set
                          </td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Reserved Characters</td>
                          <td>
                            <code>:</code> <code>/</code> <code>?</code>{' '}
                            <code>#</code> <code>[</code> <code>]</code>{' '}
                            <code>@</code> <code>!</code> <code>$</code>{' '}
                            <code>&amp;</code> <code>'</code> <code>(</code>{' '}
                            <code>)</code>
                            <code>*</code> <code>+</code> <code>,</code>{' '}
                            <code>;</code> <code>=</code>
                          </td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Unsafe Characters</td>
                          <td>
                            <code>space</code> <code>&lt;</code> <code>&gt;</code>{' '}
                            <code>{'{'}</code> <code>{'}'}</code> <code>|</code>{' '}
                            <code>`</code> <code>^</code> <code>\</code>
                          </td>
                          <td>Yes</td>
                        </tr>
                        <tr>
                          <td>Non ASCII characters</td>
                          <td>Characters outside the US-ASCII set.</td>
                          <td>Yes</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h3>Common ASCII characters and their URL encoded value</h3>
                  <div className="table-container">
                    <table>
                      <thead>
                        <tr>
                          <th>Character</th>
                          <th>URL Encoding (UTF-8)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>space</td>
                          <td>%20</td>
                        </tr>
                        <tr>
                          <td>"</td>
                          <td>%22</td>
                        </tr>
                        <tr>
                          <td>%</td>
                          <td>%25</td>
                        </tr>
                        <tr>
                          <td>&amp;</td>
                          <td>%26</td>
                        </tr>
                        <tr>
                          <td>/</td>
                          <td>%2F</td>
                        </tr>
                        <tr>
                          <td>=</td>
                          <td>%3D</td>
                        </tr>
                        <tr>
                          <td>?</td>
                          <td>%3F</td>
                        </tr>
                        <tr>
                          <td>@</td>
                          <td>%40</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p>
                    You can check out the complete ASCII character encoding
                    reference in{' '}
                    <a href="/learn/#ascii-character-encoding-reference">
                      this blog post
                    </a>
                    .
                  </p>
                  <p>
                    We also have a tool to decode URLs back to their normal
                    form. It is hosted at{' '}
                    <a href="https://www.urldecoder.io">
                      https://www.urldecoder.io
                    </a>
                    . Don't forget to check that out if you need to decode URL
                    encoded strings.
                  </p>
                  <p>
                    You can read more on the <a href="/blog">blog</a>.
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className="home-ad">
          <Adsense type="article-bottom-responsive" />
        </div>
        <Container>
          <div className="page-share">
            <Share title={this.props.data.site.siteMetadata.title} pathname={this.props.location.pathname} />
          </div>
        </Container>
        <Container>
          <div className="page-comments">
            <Disqus location={this.props.location} 
                    disqusShortname={this.props.data.site.siteMetadata.disqusShortname} />
          </div>
        </Container>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        disqusShortname
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "post" } } }
    ) {
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            type
            description
            featured_image {
              publicURL
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
