import React from 'react'
import strictUriEncode from 'strict-uri-encode'
import Container from '../Container'
import './Product.scss'

class Product extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      output: '',
    }
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if(window.innerWidth > 992) {
      this.inputRef.current.focus()
    }
  }

  handleInputChange = event => {
    const value = event.target.value
    const encodedValue = strictUriEncode(value)
    this.setState({
      output: encodedValue,
    })
  }

  copyToClipboard = event => {
    if (!event.target.value) {
      return
    }

    this.selectArea(event)

    if (document.queryCommandSupported('copy')) {
      document.execCommand('copy')
    }
  }

  selectArea = event => {
    if (event.target.value) {
      event.target.select()
    }
  }

  render () {
    return (
      <div className="product">
        <Container>
          <div className="product-form">
            <h1 className="product-heading">URL Encode online</h1>
            <form id="url-encoder-form">
              <div className="form-item">
                <textarea
                  className="form-control input-area"
                  placeholder="Type or paste your input string here..."
                  aria-label="InputString"
                  ref={this.inputRef}
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="form-item">
                <textarea
                  className="form-control output-area"
                  placeholder="URL encoded output goes here..."
                  aria-label="Output"
                  readOnly
                  onFocus={this.selectArea}
                  onClick={this.copyToClipboard}
                  value={this.state.output}
                />
              </div>
              <div className="info">
                <span>The tool uses <code>UTF-8</code> encoding scheme.</span>
                <span className="bug">
                  Found a bug? <a href="mailto:rajeevhub@gmail.com?subject=Bug in URLEncoder">report!</a>
                </span>
              </div>
            </form>
          </div>
        </Container>
      </div>
    )
  }
}

export default Product
